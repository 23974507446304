<div class="form-field flex-col gap-1"
  [class.invalid]="isTouched && invalid"
  (click)="onTouched()">
  <div class="flex-row gap-1">
    <label *ngIf="label"
      class="label"
      [innerHTML]="label">
    </label>
    <sup *ngIf="required" class="label">*</sup>

    <i *ngIf="labelIconRight"
      [ngClass]="labelIconRight"
      (click)="labelIconRightClick.emit($event)"
      [pTooltip]="labelIconRightTooltip">
    </i>
  </div>

  <div class="flex-row gap-1">
    <div class="flex-1 flex-col gap-1">
      <label class="label">
        {{ 'HTML' | translate }}
      </label>

      <textarea pInputText
        class="input"
        style="width: 100%;"
        [(ngModel)]="value"
        (ngModelChange)="htmlChanged.next($event)"
        [rows]="16">
      </textarea>
    </div>

    <div class="flex-1 flex-col gap-1">
      <label class="label">
        {{ 'Preview' | translate }}
      </label>

      <div class="flex-1"
        style="display: contents;"
        [innerHTML]="previewHTML">
      </div>
    </div>
  </div>
</div>
