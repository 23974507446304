import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { WebCenterType, WebObject, WorkspaceSolutionType, WorkspaceWidget } from 'app/center-v2/shared/models';
import { PluginLoaderService } from 'app/shared/services';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { BrowserUtils } from 'app/shared/utils';
import { DateTimeIndexUtils } from 'app/shared/utils/date-time-index.utils';
import { format } from 'date-fns';
import intlTelInput from 'intl-tel-input';
import { SelectItem } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { Dropdown } from 'primeng/dropdown';
import { Editor } from 'primeng/editor';
import { MultiSelect } from 'primeng/multiselect';

marker('QTY'); // UoM

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormFieldComponent),
  multi: true
};

@Component({
  selector: 'lc-form-field',
  templateUrl: 'form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class FormFieldComponent implements ControlValueAccessor, OnInit {

  static defaultRelationControlTypes = [
    'relation-edit-in-dropdown',
    'relation-edit-in-popup',
    'relation-inline-form',
  ];

  static knownFieldTypes = [
    'checkbox',
    'checkbox3',
    'color',
    'date',
    'datetime',
    'dropdown',
    'email',
    'geojsongeometry',
    'html',
    'json',
    'link',
    'msisdn',
    'multi-select',
    'multi-select-flags',
    'number',
    'password',
    'rating',
    'relation',
    ...FormFieldComponent.defaultRelationControlTypes,
    'richtext',
    'select-button',
    'textarea',
    'text',
    'time',
    'timespanhours',
    'timespanseconds',
    'toggle-switch',
    'warehouselocationarticletype',
    'workschedule',
  ];

  readonly noValueHtml = `<span class="no-value">-</span>`;

  @ViewChild(Calendar, { static: false }) calendar: Calendar;
  @ViewChild(Dropdown, { static: false }) dropdown: Dropdown;
  @ViewChild(Editor, { static: false }) editor: Editor;
  @ViewChild(MultiSelect, { static: false }) multiSelect: MultiSelect;
  @ViewChild('inputElement', { static: false }) inputElement: any;

  @Input() allowCopyOnHover: boolean;
  @Input() appendTo: any;
  @Input() autoFocus: boolean;
  @Input() disabled: boolean;
  @Input() calendarDisabledDates: Date[];
  @Input() calendarInline: boolean;
  @Input() calendarSelectionMode: 'multiple' | 'range' | undefined;
  @Input() calendarView: 'month' | undefined;
  @Input() calendarYearRange: string;
  @Input() colorFormat: 'hex' | 'rgb' | 'hsb' = 'hex';
  @Input() checkboxForceEmptyLabel: boolean;
  @Input() dropdownShowClear: boolean;
  @Input() editMode: boolean;
  @Input() group: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() inputAddonLeft: string;
  @Input() inputAddonRight: string;
  @Input() label: string;
  @Input() labelIconRight: string;
  @Input() labelIconRightTooltip: string;
  @Input() labelIconRight2: string;
  @Input() labelIconRight2Tooltip: string;

  @Input() set maxDate(value: Date | string | undefined) {
    this._maxDate = value;
  }
  get maxDate(): Date {
    return this.getDateValue(this._maxDate) as Date;
  }
  private _maxDate: Date | string | undefined;
  @Input() maxLength: number;
  @Input() maxNumber: number;

  @Input() set minDate(value: Date | string | undefined) {
    this._minDate = value;
  }
  get minDate(): Date {
    return this.getDateValue(this._minDate) as Date;
  }
  private _minDate?: Date | string;
  @Input() minLength: number;
  @Input() minNumber: number;

  @Input() set options(value: SelectItem[] | any[]) {
    this._options = (value || []).map((item: SelectItem) => {
      if (item.label) item.label = this.translateService.instant(item.label);
      return item;
    });
  }
  get options(): SelectItem[] | any[] {
    return this._options;
  }
  private _options: SelectItem[] | any[];
  @Input() optionLabel: string;
  @Input() optionValue: string;
  @Input() pattern: string;
  @Input() placeholder: string;
  @Input() ratingStars: number;
  @Input() readOnly: boolean;
  @Input() relationLinkActive?: boolean;
  @Input() relationName?: string;
  @Input() relationOptions?: SelectItem[];
  @Input() relationParentWebObject?: WebObject;
  @Input() relationSolutionType?: WorkspaceSolutionType;
  @Input() relationWebObject?: WebObject;
  @Input() required: boolean;
  @Input() selectedItemsLabel: boolean;
  @Input() showMultiSelectHeader: boolean;
  @Input() solutionTypes: WorkspaceSolutionType[];
  @Input() step: number;
  @Input() tabIndex: number;
  @Input() textAreaRows: number;
  @Input() textMask: string;
  @Input() textSelectOnFocus: boolean;
  @Input() type: string;
  @Input() webCenterTypes: WebCenterType[];

  @Output() editorToolbarWidgetLinkClick = new EventEmitter<Editor>();
  @Output() labelIconRightClick = new EventEmitter<MouseEvent>();
  @Output() labelIconRight2Click = new EventEmitter<MouseEvent>();
  @Output() onBlur = new EventEmitter<any>();
  @Output() relationLinkClick = new EventEmitter<MouseEvent>();
  @Output() relationEditClick = new EventEmitter<MouseEvent>();
  @Output() valueLinkClick = new EventEmitter<MouseEvent>();

  onChange: (x: any) => {};
  onTouched: () => {};

  copyToClipboardMousePosition = { x: 0, y: 0 };
  dateValue?: Date[] | Date;
  invalid: boolean;
  isQuillLoaded: boolean;
  isTouched: boolean;
  iti: any;
  readonlyValue: string;
  relationControlType?: 'edit-in-dropdown' | 'edit-in-popup' | 'inline-form';
  timeValue?: Date;
  multiSelectFlagsValue?: number[];
  value: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private pluginLoaderService: PluginLoaderService,
    private translateService: TranslateService,
  ) {
    const now = new Date();
    this.calendarYearRange = `${now.getFullYear() - 10}:${now.getFullYear() + 5}`;

    setTimeout(() => {
      if (this.type !== 'richtext') return;

      this.pluginLoaderService.load('quill')
      .subscribe((pluginLoaded: boolean) => {
        this.isQuillLoaded = pluginLoaded;

        this.cdr.markForCheck();
      });
    }, 100);
  }

  private getDateValue(value: Date | string | number | undefined): Date | undefined {
    if (!value) return undefined;

    if (value instanceof Date) {
      return value;
    } else if (typeof value === 'string') {
      return new Date(value + (value.indexOf('T') < 0 ? 'T00:00:00Z' : ''));
    } else if (typeof value === 'number') {
      return DateTimeIndexUtils.decodeDayIndexToDate(value);
    }

    return undefined;
  }

  private getTimeValue(value: Date | string | number | undefined): Date | undefined {
    if (!value) return undefined;

    if (value instanceof Date) {
      return value;
    } else if (typeof value === 'string') {
      if (value.indexOf('T') >= 0) {
        return new Date(value);
      }
      if (value.length === 4) value = value.slice(0, 2) + ':' + value.slice(2);

      return new Date((new Date()).toISOString().substring(0, 11) + (value || '00:00:00'));
    } else if (typeof value === 'number') {
      return new Date((new Date()).toISOString().substring(0, 11) + DateTimeIndexUtils.decodeSecondsIndexToTimeString(value));
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    this.type = (this.type || '').toLowerCase();
    if (FormFieldComponent.defaultRelationControlTypes.indexOf(this.type) >= 0) {
      this.relationControlType = this.type.slice('relation-'.length) as any;
      this.type = 'relation';
    }
    if (this.value == value) return;

    this.value = value;

    if (this.value && this.type === 'date' || this.type === 'datetime') {
      if (Array.isArray(this.value)) this.dateValue = this.value.map(x => this.getDateValue(x));
      else this.dateValue = this.getDateValue(this.value);
      this.updateValueWithDate(this.dateValue, true);
    } else if (this.value && this.type === 'time') {
      this.timeValue = this.getTimeValue(this.value);
      this.updateValueWithTime(this.timeValue, true);
    } else if (this.value && this.type === 'color') {
      setTimeout(() => {
        this.onChange(this.value); // this is required for the color picker to display the initial value...
      });
    } else if (this.type === 'checkbox') {
      this.value = this.value === 'true' || this.value === true;
    } else if (this.type === 'dropdown') {
      const option = (this.options || []).find((item: any) => {
        return item.value != null ? item.value == this.value : item === this.value;
      });
      this.readonlyValue = option ? option[this.optionLabel || 'label'] : undefined;
      if (this.value !== option?.value && this.value == option?.value) this.value = option?.value;
    } else if (this.type === 'multi-select-flags') {
      this.options = (this.options || []).filter((item: any) => {
        return item.value;
      });
      this.multiSelectFlagsValue = this.getMultiSelectValue(this.value);
      this.readonlyValue = (this.multiSelectFlagsValue || []).map((x: number) => {
        const option = (this.options || []).find((item: any) => {
          return item.value != null ? item.value == x : item === x;
        });
        return option ? option[this.optionLabel || 'label'] : undefined;
      }).join(', ');
    } else if (this.value && this.type === 'text') {
      this.value = this.value.toString()?.replace(/\n/g, ' ');
    } else if (this.value && this.type === 'number') {
      this.value = parseFloat(this.value.toString());
    }

    this.cdr.markForCheck();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = (value: any) => {
      if (this.type !== 'msisdn') this.invalid = this.required && (value == null || value === '' || (typeof value !== 'object' && typeof value !== 'boolean' && typeof value !== 'number' && !value?.length));
      return fn(value);
    };
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = () => {
      this.isTouched = true;
      return fn();
    };
  }

  ngOnInit() {
    // this.type = this.type === 'datetime' ? 'date' : this.type;
    if (this.label) this.label = this.translateService.instant(this.label);

    setTimeout(() => {
      if (this.type === 'msisdn' && this.editMode) {
        const input = this.inputElement?.nativeElement || this.inputElement;
        this.iti = intlTelInput(input, {
          countryOrder: ['se'],
          initialCountry: 'se',
          // nationalMode: false,
          // separateDialCode: true,
          utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/js/utils.js',
        });
      } else if (['richtext', 'textarea'].indexOf(this.type) >= 0) {
        this.disableKeysPropagation();
      }

      if (this.autoFocus) {
        this.tryToFocusInputElement();
      }
    }, 500);
  }

  tryToFocusInputElement(): void {
    if (!this.inputElement) return;

    const el = this.inputElement.nativeElement || this.inputElement;
    if (el?.select) el.select();
    if (el?.focus) el.focus();
  }

  updateValueWithNumber(value: string) {
    this.onChange(value != null ? parseFloat(value) : value);
    this.cdr.markForCheck();
  }

  updateValueWithPhoneNumber(value: string) {
    const phoneNumber = this.iti.getNumber();

    this.invalid = phoneNumber && !this.iti.isValidNumber();

    this.onChange(this.invalid ? null : phoneNumber);
    this.cdr.markForCheck();
  }

  updateValueWithDate(dt: Date[] | Date | undefined, skipOnChange?: boolean, ev?: any) {
    if (ev && (ev?.target.value || '').length != 10) return;

    this.value = Array.isArray(dt) ? dt : dt ? format(dt, 'yyyy-MM-dd') : null;

    if (!skipOnChange) this.onChange(this.value);
    this.cdr.markForCheck();
  }

  updateValueWithTime(dt: Date | undefined, skipOnChange?: boolean) {
    this.value = dt ? format(dt, 'HH:mm:ss') : null;

    if (!skipOnChange) this.onChange(this.value);
    this.cdr.markForCheck();
  }

  disableKeysPropagation(allowedKeyCallback?: (event: KeyboardEvent) => void) {
    if (!this.inputElement) return;

    (this.inputElement.nativeElement || this.inputElement.inputViewChild?.nativeElement || this.inputElement.el?.nativeElement)
    .addEventListener('keydown', (event: any) => {
      const keyCode = event.keyCode;

      const KEY_ENTER = 13;
      const KEY_LEFT = 37;
      const KEY_UP = 38;
      const KEY_RIGHT = 39;
      const KEY_DOWN = 40;
      const KEY_PAGE_UP = 33;
      const KEY_PAGE_DOWN = 34;
      const KEY_PAGE_HOME = 36;
      const KEY_PAGE_END = 35;
      const preventKeyCodes = [
        ['richtext', 'textarea'].indexOf(this.type) >= 0 ? KEY_ENTER : undefined,
        KEY_LEFT, KEY_UP, KEY_RIGHT, KEY_DOWN, KEY_PAGE_UP, KEY_PAGE_DOWN, KEY_PAGE_HOME, KEY_PAGE_END
      ]
      .filter(x => x);

      if (preventKeyCodes.indexOf(keyCode) >= 0) {
        event.stopPropagation();
      } else if (allowedKeyCallback) {
        allowedKeyCallback(event);
      }
    });
  }

  showPicker() {
    if (this.dropdown) {
      this.dropdown.show();
      this.cdr.markForCheck();
    } else if (this.multiSelect) {
      this.multiSelect.show();
      this.cdr.markForCheck();
    } else if (this.calendar) {
      this.calendar.showOverlay();
      this.cdr.markForCheck();
    }
  }

  private getMultiSelectValue(value: number): number[] | undefined {
    if (value != null) {
      const multiSelectFlagsValue = [];
      for (const option of this.options || []) {
        if ((value & option.value) === option.value) {
          multiSelectFlagsValue.push(option.value);
        }
      }
      return multiSelectFlagsValue;
    } else {
      return undefined;
    }
  }

  setValueFromMultiSelectFlagsValue(selectedValues: number[]) {
    this.value = null;
    for (const selectedValue of selectedValues || []) {
      this.value |= selectedValue;
    }

    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  copyToClipboardMouseDown(ev: MouseEvent) {
    if (!this.allowCopyOnHover) return;

    this.copyToClipboardMousePosition.x = ev.screenX;
    this.copyToClipboardMousePosition.y = ev.screenY;
  }

  copyToClipboardClick(ev: MouseEvent) {
    if (!this.allowCopyOnHover) return;
    if (this.copyToClipboardMousePosition.x !== ev.screenX || this.copyToClipboardMousePosition.y !== ev.screenY) return;

    ev.stopPropagation();

    BrowserUtils.copyToClipboard(this.value || '');

    this.notificationService.info(
      this.translateService.instant('Info'),
      this.translateService.instant('Content copied to the clipboard.'),
    );
  }

  log(x) {
    console.log(x);
  }
}
