import { Injectable } from '@angular/core';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { BrowserUtils } from 'app/shared/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class SolutionDataService {

  static SolutionDataEngineV1GuidId = 'c109e71a-d621-44c6-8ef5-cc1f36562d1e';

  private urlSuffixPlaceholder = 'centerv2/solutiondata/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  runMap(
    solutionDataMapCallGuidId: string,
    resourceGuidId: string,
    fromSource: boolean,
    sourceRootTypeGuidId?: string,
    sourceGuidId?: string,
    sourceRelationTypeGuidId?: string,
  ): Observable<void> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'map/run'),
      {
        solutionDataMapCallGuidId: solutionDataMapCallGuidId,
        resourceGuidId: resourceGuidId,
        fromSource: fromSource,
        sourceRootTypeGuidId: sourceRootTypeGuidId,
        sourceGuidId: sourceGuidId,
        sourceTypeRelationGuidId: sourceRelationTypeGuidId
      }
    ).pipe(
      map((response: any) => {
        return response?.solutionDataMapRunWeb2Object ? response?.solutionDataMapRunWeb2Object : null;
      })
    );
  }

  getModel(
    solutionDataMapCallGuidId: string,
    contentType: string,
    fromSource: boolean,
    sourceRootTypeGuidId?: string,
    sourceGuidId?: string,
    sourceRelationTypeGuidId?: string,
  ): Observable<Blob> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'map/getmodel'),
      {
        solutionDataMapCallGuidId: solutionDataMapCallGuidId,
        contentType: contentType,
        fromSource: fromSource,
        sourceRootTypeGuidId: sourceRootTypeGuidId,
        sourceGuidId: sourceGuidId,
        sourceTypeRelationGuidId: sourceRelationTypeGuidId
      },
      {
        observe: 'body',
        responseType: 'blob',
      }
    );
  }

  reportTemplateGetVersionReportData(reportTemplateVersionGuidId: string): Observable<Blob> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'report/template/getversionreportdata'),
      {
        reportTemplateVersionGuidId: reportTemplateVersionGuidId,
      },
      {
        observe: 'body',
        responseType: 'blob',
      }
    );
  }

  reportTemplateSetVersionReportData(
    reportTemplateVersionGuidId: string,
    resourceBlob: Blob,
  ): Observable<any> {
    let formParams: { append(param: string, value: any): any; };
    formParams = new FormData();

    formParams = formParams.append('siteGuidId', BrowserUtils.getQueryParams().siteGuidId) || formParams;

    if (reportTemplateVersionGuidId !== undefined) {
      formParams = formParams.append('reportTemplateVersionGuidId', reportTemplateVersionGuidId) || formParams;
    }

    if (resourceBlob !== undefined) {
      formParams = formParams.append('contentType', resourceBlob.type) || formParams;
      formParams = formParams.append('content', resourceBlob) || formParams;
    }
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'report/template/setversionreportdata'),
      formParams,
    ).pipe(
      map((response: any) => {
        return response/* ? new WebResponse(response) : null*/;
      })
    );
  }

}
