import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WebCenterType } from 'app/center-v2/shared/models';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { CenterType, ReportSolutionTypeType } from 'app/shared/models';
import { SessionService } from 'app/shared/services/app';
import { EnumUtils } from 'app/shared/utils';
import { SelectItem } from 'primeng/api';



@Component({
  selector: 'lc-select-center-type-dialog',
  templateUrl: './select-center-type.dialog.html',
  styleUrls: ['./select-center-type.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectCenterTypeDialog extends BaseDialog {

  centerTypeItems: SelectItem[];
  reportSolutionTypeTypeItems: SelectItem[];

  centerTypeGuidId: string;
  hideName: boolean;
  name: string;
  reportSolutionTypeType: ReportSolutionTypeType;


  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);
  }

  init(data: any) {
    this.centerTypeItems = (data?.centerTypes || [])
    .map((ct: CenterType | WebCenterType) => {
      return { value: ct.typeGuidId, label: ct.name };
    });

    this.reportSolutionTypeTypeItems = data.isReportSolutionType ? EnumUtils.toSelectItemArray(ReportSolutionTypeType) : undefined;
    this.reportSolutionTypeType = ReportSolutionTypeType.Object;

    this.hideName = data?.hideName;
  }

  centerTypeChanged(centerTypeGuidId: string) {
    const centerType = this.centerTypeItems.find(ct => ct.value === centerTypeGuidId);
    if (centerType) {
      this.name = centerType.label.replace(/Logic/, '');
    } else {
      this.name = '';
    }
    this.cdr.markForCheck();
  }

  submit(form: NgForm) {
    if (form.valid) {
      this.close({
        centerTypeGuidId: this.centerTypeGuidId,
        reportSolutionTypeType: this.reportSolutionTypeType,
        name: this.name,
      });
      form.reset();
    }
  }

}
