<p-tree class="tree-panel"
  [selectionMode]="!singleSelection ? 'single' : null"
  [selection]="selectedTN"
  (onNodeExpand)="onNodeExpand($event.node)"
  (onNodeSelect)="onNodeSelect($event.node)"
  [value]="treeNodes">
  <ng-template let-node [pTemplate]="TYPE_CHECKBOX">
    <div [class.bold]="hasSubMembers(node)"
      style="white-space: nowrap;">
      <p-checkbox *ngIf="!singleSelection && node.data?.guidId !== 'addSubType'"
        binary="true"
        [ngModel]="isNodeChecked(node)"
        (onChange)="onNodeCheckedChanged(node, $event)"
        [disabled]="solutionType?.coreType === 2 && node.data.isList"
        [pTooltip]="solutionType?.coreType === 2 && node.data.isList ? 'List members cannot be selected' : undefined">
      </p-checkbox>

      <p-radioButton *ngIf="singleSelection && node?.data.member"
        [value]="node"
        name="selectedTN"
        [ngModel]="selectedTN"
        (ngModelChange)="onNodeCheckedChanged(node, $event)">
      </p-radioButton>

      <span [class.line-through]="node.data.relation && (!node.data.relation?.web2Relation || !node.data.relation?.web2Access === 0)">
        {{ node.label }}
      </span>

      <i *ngIf="node.data.guidId === 'addSubType'"
        class="link fas fa-plus"
        (click)="addSubType(node)">
      </i>

      <span *ngIf="node.data.relation && node.data.relation.name !== 'Self Relation'"
        [class.not-anymore]="node.data.parentIsList">
        ({{ node.data.isList ? 'List' : 'Single' }})
      </span>

      <span *ngIf="node.data.parentIsList"> (List)</span>

      <span *ngIf="node.data.centerType"> ({{ node.data.centerType.name }})</span>
    </div>
  </ng-template>
</p-tree>

<lc-select-center-type-dialog></lc-select-center-type-dialog>
